<template>
  <div class="card" @click="goWebtoon">
    <img
      v-lazyload
      class="card-img-top"
      :data-url="thumbnailUrl"
      src="/img-loading.gif"
      @error="imageError"
    />
    <div class="badge-layer px-2 pt-1">
      <small
        class="badge rounded-pill bg-warning text-dark me-1"
        v-show="item.readDate"
        ><i class="bi bi-book"></i> 읽음</small
      >
      <small class="badge rounded-pill me-1 bg-danger" v-show="item.isAdult"
        >19</small
      >
      <small
        class="badge rounded-pill me-1 bg-orange text-dark"
        v-show="item.isUpdated"
        >Update</small
      >
      <small
        class="badge rounded-pill me-1 bg-info text-dark"
        v-show="item.isNew"
        >신간</small
      >
      <small class="badge rounded-pill me-1 bg-success" v-show="item.isEnd"
        >완결</small
      >
      <small
        class="badge rounded-pill me-1 bg-primary"
        v-show="item.isSeasonEnd"
        >시즌 완결</small
      >
      <small
        class="badge rounded-pill me-1 bg-info text-dark"
        v-show="item.isDelay"
        >휴재</small
      >
      <small
        class="badge rounded-pill bg-secondary me-1"
        v-for="genre of item.genre"
        :key="genre"
        >{{ genre }}</small
      >
      <div></div>
    </div>
    <div class="title-layer py-1 px-2">
      <div class="text-truncate text-light">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["item"],
  computed: {
    ...mapState(["config"]),
    thumbnailUrl() {
      const query = new URLSearchParams();
      query.append("customOriginHost", this.config.toonSarangThumbnailUrl);
      query.append("customHostAlias", "webtoon-thumbnail");
      return `/image-cache${this.item.thumbnail}?${query}`;
    },
  },
  methods: {
    goWebtoon() {
      this.$router.push({
        name: "Webtoon",
        params: { link: this.item.link.slice(1) },
      });
    },
    imageError(e) {
      e.target.src = "/img-loading.gif";
    },
  },
  directives: {
    lazyload: {
      mounted: (el) => {
        function loadImage() {
          const isImg = el.nodeName === "IMG";
          // 이미지 태그일 경우만 url 입력 로딩
          if (isImg) {
            el.src = el.dataset.url;
          }
        }

        function createObserver() {
          const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                // 감지대상이 교차영역에 진입 할 경우
                loadImage(); // 교차영역 들어올경우 이미지 로딩
                observer.unobserve(el); // 이미지 로딩 이후론 관찰할 필요 x
              }
            });
          });

          observer.observe(el);
        }

        // 지원하지 않는 브라우저는 바로 이미지 로딩을 시켜주도록 호환성 체크
        window["IntersectionObserver"] ? createObserver() : loadImage();
      },
    },
  },
};
</script>

<style scoped>
.card {
  margin: 0.2rem;
  cursor: pointer;
}
.badge-layer {
  position: absolute;
  top: 0;
}
.title-layer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.425);
  font-size: 1.3rem;
  text-align: center;
  font-weight: bold;
}
.bg-orange {
  background: #fd7e14;
}
</style>
