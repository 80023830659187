<template>
  <div class="row mt-5">
    <div class="col-md"></div>
    <div class="col-md-4">
      <h2>계정 만들기</h2>
      <form @submit.prevent="onSubmit">
        <div class="mb-3">
          <label for="usernameInput" class="form-label">Username</label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': isInvalidUsername }"
            id="usernameInput"
            aria-describedby="usernameHelp"
            v-model="username"
          />
          <div class="invalid-feedback">{{ invalideUsernameHelpText }}</div>
        </div>
        <div class="mb-3">
          <label for="passwordInput" class="form-label">Password</label>
          <input
            type="password"
            class="form-control"
            :class="{ 'is-invalid': isInvalidPassword }"
            id="passwordInput"
            aria-describedby="passwordHelp"
            v-model="password"
          />
          <div class="invalid-feedback">Wrong password</div>
        </div>
        <div class="mb-3">
          <label for="passwordConfirmInput" class="form-label"
            >Password Confirm</label
          >
          <input
            type="password"
            class="form-control"
            :class="{ 'is-invalid': isInvalidPasswordConfirm }"
            id="passwordConfirmInput"
            aria-describedby="passwordConfirmHelp"
            v-model="passwordConfirm"
          />
          <div class="invalid-feedback">비밀번호 일치 하지않음</div>
        </div>
        <p>계정생성 후 관리자의 승인이 필요합니다.</p>
        <div class="d-grid gap-3">
          <button type="submit" class="btn btn-primary" :disabled="!isValid">
            생성
          </button>
        </div>
      </form>
    </div>
    <div class="col-md"></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    username: "",
    password: "",
    passwordConfirm: "",
    isInvalidUsername: false,
    invalideUsernameHelpText: "유효하지 않은 Username",
    isInvalidPassword: false,
  }),
  computed: {
    isInvalidPasswordConfirm() {
      return (
        this.password &&
        this.passwordConfirm &&
        this.password !== this.passwordConfirm
      );
    },
    isValid() {
      if (!this.username || !this.username.trim()) return false;
      if (!this.password || !this.password.trim()) return false;
      if (this.password !== this.passwordConfirm) return false;
      return true;
    },
  },
  methods: {
    ...mapActions(["join"]),
    async onSubmit() {
      this.isInvalidUsername = false;
      this.isInvalidPassword = false;
      if (!this.username || !this.username.trim())
        this.isInvalidUsername = true;
      if (!this.password || !this.password.trim())
        this.isInvalidPassword = true;
      try {
        await this.join({
          username: this.username,
          password: this.password,
        });
        const { name, params, query } = this.$store.state.route.query;
        if (!name) {
          this.$router.push({ path: "/" });
        } else {
          this.$router.push({
            name,
            params: JSON.parse(params),
            query: JSON.parse(query),
          });
        }
      } catch (err) {
        if (err.code === "INVALID_USERNAME") {
          this.invalideUsernameHelpText = "유효하지 않은 Username";
          this.isInvalidUsername = true;
        } else if (err.code === "EXIST_USERNAME") {
          this.invalideUsernameHelpText = "이미 사용중인 Username";
          this.isInvalidUsername = true;
        } else if (err.code === "INVALID_PASSWORD") {
          this.isInvalidPassword = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
