<template>
  <header>
    <nav class="nav-bar bg-white text-dark p-2">
      <div class="container">
        <div class="row">
          <div class="col-auto align-self-center">
            <router-link to="/" class="fs-4 fw-bold text-dark"
              >Toon Fox</router-link
            >
          </div>
          <div class="col align-self-center p-0">
            <form @submit.prevent="goSearch()">
              <input
                class="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
                v-model="searchText"
              />
            </form>
          </div>
          <div class="col-auto align-self-center">
            <div class="btn-group">
              <button
                type="button"
                class="member-button btn btn-outline-secondary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  fill="currentColor"
                  class="bi bi-person-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"></path>
                  <path
                    fill-rule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                  ></path>
                </svg>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <router-link :to="{ name: 'Recently' }" class="dropdown-item"
                    >최근 본 작품</router-link
                  >
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <a class="dropdown-item" href="#" @click.prevent="doLogout()"
                    >로그아웃</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>

  <div class="container mt-3">
    <router-view :key="$router.path" />
  </div>

  <footer class="p-5 mb-5"></footer>

  <div class="toast-container position-fixed bottom-0 end-0 p-4">
    <BootstrapToast
      v-for="toast in $store.state.toasts"
      :key="toast.key"
      :data="toast"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import BootstrapToast from "@/components/BootstrapToast.vue";

export default {
  data() {
    return {
      searchText: "",
    };
  },
  computed: {
    // ...mapState("page", ["categories", "categoryStyles"]),
  },
  components: {
    BootstrapToast,
  },
  methods: {
    ...mapActions(["logout"]),
    async doLogout() {
      await this.logout();
      this.$store.commit("setMember", null);
      this.$store.commit("setConfig", {});
      this.$router.push({ name: "Login" });
    },
    goSearch() {
      const query = { ...this.$route.query };
      query.search = (this.searchText || "").trim();
      this.searchText = "";
      if (!query.search) {
        delete query.search;
      }
      this.$router.push({ name: "WebtoonList", query });
    },
  },
  created() {
    // this.$store.dispatch("page/loadCategories");
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.nav-bar a {
  text-decoration: none;
}
.nav-bar {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%), inset 0 -1px 0 rgb(0 0 0 / 15%);
}

.member-button.dropdown-toggle::after {
  display: none;
}
</style>
