import { createApp } from "vue";
import App from "./App.vue";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import { sync } from "vuex-router-sync";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

sync(store, router);

const app = createApp(App);

app.use(store).use(router).use(VueViewer).mount("#app");
