import {
  getWebtoons,
  getWebtoon,
  getEpisode,
  getGenres,
  getReadWebtoons,
  removeReadHistory,
} from "../../api-client.js";
export default {
  namespaced: true,
  state: {
    genres: [],
    webtoons: [],
    webtoon: null,
    episode: null,
    readWebtoons: [],
    webtoonsArrId: +new Date(),
  },
  mutations: {
    setGenres(state, genres) {
      state.genres = genres;
    },
    clearWebtoons(state) {
      state.webtoons = [];
      state.webtoonsArrId++;
    },
    setWebtoons(state, webtoons) {
      state.webtoons.push(...webtoons);
    },
    setWebtoon(state, webtoon) {
      state.webtoon = webtoon;
    },
    setEpisode(state, episode) {
      state.episode = episode;
    },
    setReadWebtoons(state, webtoons) {
      state.readWebtoons = webtoons;
    },
    removeReadWebtoonsByLink(state, link) {
      for (let i = 0; i < state.readWebtoons.length; ++i) {
        if (state.readWebtoons[i].link === link) {
          state.readWebtoons.splice(i, 1);
          break;
        }
      }
    },
  },
  getters: {
    currentWebtoonLink(state, getters, rootState) {
      if (rootState.route.name !== "Webtoon") return "";
      return rootState.route.params.link;
    },
    currentEpisodeLink(state, getters, rootState) {
      if (rootState.route.name !== "Episode") return "";
      return rootState.route.params.link;
    },
    webtoonListKey(state, getters, rootState) {
      const { search, genre, updated, isNew, seasonEnd, end, delay, adult } =
        rootState.route.query;
      return `${rootState.route.name}:${search}:${genre}:${updated}:${isNew}:${seasonEnd}:${end}:${delay}:${adult}`;
    },
    loadWebtoonsParam(state, getters, rootState) {
      const params = {};
      const { search, genre, updated, isNew, seasonEnd, end, delay, adult } =
        rootState.route.query;
      if (search) params.search = search;
      if (genre) params.genre = genre;
      if (updated) params.isUpdated = updated;
      if (isNew) params.isNew = isNew;
      if (seasonEnd) params.isSeasonEnd = seasonEnd;
      if (end) params.isEnd = end;
      if (delay) params.isDelay = delay;
      if (adult) params.isAdult = adult;
      return params;
    },
  },
  actions: {
    async loadWebtoons({ state, commit, getters }, { skip, limit }) {
      if (!skip) commit("clearWebtoons");
      const targetArrId = state.webtoonsArrId;
      const { webtoons } = await getWebtoons({
        ...getters.loadWebtoonsParam,
        skip,
        limit,
      });
      const isEnd = webtoons.length < limit;
      let stop = false;
      if (targetArrId === state.webtoonsArrId) {
        commit("setWebtoons", webtoons);
      } else {
        stop = true;
        console.log("webtoon load stop");
      }
      return { isEnd, skip, limit, stop };
    },
    async loadWebtoon({ getters, commit }) {
      commit("setWebtoon", null);
      if (!getters.currentWebtoonLink) return;
      const data = await getWebtoon(getters.currentWebtoonLink);
      commit("setWebtoon", data.webtoon);
    },
    async loadEpisode({ getters, commit }) {
      commit("setEpisode", null);
      if (!getters.currentEpisodeLink) return;
      const data = await getEpisode(getters.currentEpisodeLink);
      commit("setEpisode", data.episode);
    },
    async loadGenres({ commit }) {
      const { genres } = await getGenres();
      commit("setGenres", genres);
    },
    async loadReadWebtoons({ commit }, { skip, limit }) {
      commit("setReadWebtoons", []);
      const { webtoons } = await getReadWebtoons(skip, limit);
      commit("setReadWebtoons", webtoons);
    },
    async removeReadHistory({ commit }, link) {
      const [{ deletedCount }] = await removeReadHistory(link);
      if (deletedCount) {
        commit("removeReadWebtoonsByLink", link);
      }
    },
  },
  modules: {},
};
