<template>
  <div class="row pt-2">
    <div class="col-auto align-self-center"><h2>최근 본 작품</h2></div>
  </div>

  <div
    class="webtoon-item row border border-1 rounded mb-2"
    v-for="webtoon in readWebtoons"
    :key="webtoon.link"
  >
    <div class="col-4 col-md-4 col-lg-2 px-0">
      <img
        class="thumbnail-image rounded"
        v-lazyload
        :data-url="thumbnailUrl(webtoon.thumbnail)"
        src="/img-loading.gif"
        @error="imageError"
      />
    </div>
    <div class="col">
      <div class="row">
        <div class="col">
          <span class="fs-3">{{ webtoon.title }}</span>
        </div>
        <div class="col-auto pt-2">
          <button
            type="button"
            class="btn btn-sm btn-danger"
            @click="removeReadHistory(webtoon.link)"
          >
            기록삭제
          </button>
        </div>
      </div>
      <div class="badge-layer">
        <small
          class="badge rounded-pill bg-warning text-dark me-1"
          v-show="webtoon.readDate"
          ><i class="bi bi-book"></i>&nbsp;{{
            timeForToday(webtoon.readDate)
          }}&nbsp;읽음</small
        >
        <small
          class="badge rounded-pill me-1 bg-danger"
          v-show="webtoon.isAdult"
          >19</small
        >
        <small
          class="badge rounded-pill me-1 bg-orange text-dark"
          v-show="webtoon.isUpdated"
          >Update</small
        >
        <small
          class="badge rounded-pill me-1 bg-info text-dark"
          v-show="webtoon.isNew"
          >신간</small
        >
        <small class="badge rounded-pill me-1 bg-success" v-show="webtoon.isEnd"
          >완결</small
        >
        <small
          class="badge rounded-pill me-1 bg-primary"
          v-show="webtoon.isSeasonEnd"
          >시즌 완결</small
        >
        <small
          class="badge rounded-pill me-1 bg-info text-dark"
          v-show="webtoon.isDelay"
          >휴재</small
        >
        <small
          class="badge rounded-pill bg-secondary me-1"
          v-for="genre of webtoon.genre"
          :key="genre"
          >{{ genre }}</small
        >
        <div></div>
      </div>
      <div>
        {{ webtoon.summary }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { timeForToday } from "../util.js";

export default {
  created() {
    this.loadReadWebtoons({ skip: 0, limit: 100 });
  },
  computed: {
    ...mapState(["config"]),
    ...mapState("webtoon", ["readWebtoons"]),
  },
  methods: {
    ...mapActions("webtoon", ["loadReadWebtoons", "removeReadHistory"]),
    thumbnailUrl(thumbnailPath) {
      const query = new URLSearchParams();
      query.append("customOriginHost", this.config.toonSarangThumbnailUrl);
      query.append("customHostAlias", "webtoon-thumbnail");
      return `/image-cache${thumbnailPath}?${query}`;
    },
    timeForToday(value) {
      return timeForToday(value);
    },
  },
  directives: {
    lazyload: {
      mounted: (el) => {
        function loadImage() {
          const isImg = el.nodeName === "IMG";
          // 이미지 태그일 경우만 url 입력 로딩
          if (isImg) {
            el.src = el.dataset.url;
          }
        }

        function createObserver() {
          const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                // 감지대상이 교차영역에 진입 할 경우
                loadImage(); // 교차영역 들어올경우 이미지 로딩
                observer.unobserve(el); // 이미지 로딩 이후론 관찰할 필요 x
              }
            });
          });

          observer.observe(el);
        }

        // 지원하지 않는 브라우저는 바로 이미지 로딩을 시켜주도록 호환성 체크
        window["IntersectionObserver"] ? createObserver() : loadImage();
      },
    },
  },
};
</script>

<style scoped>
.thumbnail-image {
  width: 100%;
}
.webtoon-item {
  --bs-gutter-x: 0.5rem;
}
</style>
