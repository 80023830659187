import { createStore } from "vuex";
import webtoon from "./modules/webtoon";
import { login, logout, join } from "../api-client.js";

export default createStore({
  state: {
    toasts: [],
    member: null,
    config: {},
  },
  mutations: {
    setMember(state, member) {
      state.member = member;
    },
    setConfig(state, config) {
      state.config = config;
    },
    addToast(state, toastData) {
      state.toasts.unshift(toastData);
    },
    removeToast(state, data) {
      for (let i = 0; i < state.toasts.length; ++i) {
        if (state.toasts[i] === data) {
          state.toasts.splice(i, 1);
          return;
        }
      }
    },
  },
  actions: {
    async login(store, { username, password }) {
      return await login(username, password);
    },
    async logout() {
      return await logout();
    },
    async join(store, { username, password }) {
      return await join(username, password);
    },
  },
  modules: {
    webtoon,
  },
});
