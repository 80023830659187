<template>
  <div
    ref="el"
    class="toast"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div class="toast-header">
      <span v-show="data.status === 'success'" class="badge bg-success me-2"
        >Success</span
      >
      <span v-show="data.status === 'error'" class="badge bg-danger me-2"
        >Error</span
      >

      <strong class="me-auto">{{ data.title }}</strong>
      <small class="text-muted">just now</small>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="toast"
        aria-label="Close"
      ></button>
    </div>
    <div class="toast-body">{{ data.message }}</div>
  </div>
</template>

<script>
import { Toast } from "bootstrap";

export default {
  props: ["data"],
  mounted() {
    const toast = new Toast(this.$refs.el);
    this.$refs.el.addEventListener("hidden.bs.toast", () => {
      this.$store.commit("removeToast", this.data);
    });
    toast.show();
  },
};
</script>

<style lang="scss" scoped></style>
