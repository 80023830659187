<template>
  <div
    class="row pt-2"
    v-show="readWebtoons && readWebtoons.length && !$route.query.search"
  >
    <div class="col-auto fs-4 align-self-center">최근 본 작품</div>
    <div class="col"></div>
    <div class="col-auto">
      <router-link
        :to="{ name: 'Recently' }"
        class="btn btn-outline-secondary btn-sm"
        role="button"
        >더보기</router-link
      >
    </div>
  </div>
  <div
    class="row pb-4 mb-4"
    v-show="readWebtoons && readWebtoons.length && !$route.query.search"
  >
    <div
      class="col-6 col-md-4 col-lg-2 px-0"
      v-for="webtoon in readWebtoons"
      :key="webtoon.link"
    >
      <WebtoonCard :item="webtoon" />
    </div>
  </div>

  <div class="row border-bottom py-2" v-show="$route.query.search">
    <div class="col-sm-2 fs-4 align-self-center">검색어</div>
    <div class="col-sm-10">
      <span
        class="search-keyword-badge badge text-dark bg-white fs-4 border border-1"
        @click="removeSearch"
        >{{ $route.query.search }} <i class="bi bi-x-lg fs-6"></i
      ></span>
    </div>
  </div>
  <div class="row border-bottom py-2">
    <div class="col-sm-2 fs-4">장르</div>
    <div class="col-sm-10">
      <span
        class="genre-filter-item badge me-1"
        :class="{
          'bg-light': $route.query.genre,
          'text-dark': $route.query.genre,
          'bg-primary': !$route.query.genre,
        }"
        @click="setGenrefilter()"
        >전체</span
      >
      <span
        class="genre-filter-item badge me-1"
        :class="{
          'bg-light': genre !== $route.query.genre,
          'text-dark': genre !== $route.query.genre,
          'bg-primary': genre === $route.query.genre,
        }"
        v-for="genre in genres"
        :key="genre"
        @click="setGenrefilter(genre)"
        >{{ genre }}</span
      >
    </div>
  </div>
  <div class="row py-2">
    <div class="col-sm-2 fs-4">필터</div>
    <div class="col-sm-10">
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="checkbox"
          id="inlineCheckbox1"
          value="updated"
          v-model="checkedFilter"
        />
        <label class="form-check-label" for="inlineCheckbox1">최신</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="checkbox"
          id="inlineCheckbox2"
          value="isNew"
          v-model="checkedFilter"
        />
        <label class="form-check-label" for="inlineCheckbox2">신간</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="checkbox"
          id="inlineCheckbox3"
          value="seasonEnd"
          v-model="checkedFilter"
        />
        <label class="form-check-label" for="inlineCheckbox3">시즌 완결</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="checkbox"
          id="inlineCheckbox4"
          value="end"
          v-model="checkedFilter"
        />
        <label class="form-check-label" for="inlineCheckbox4">완결</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="checkbox"
          id="inlineCheckbox5"
          value="delay"
          v-model="checkedFilter"
        />
        <label class="form-check-label" for="inlineCheckbox5">휴재</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="checkbox"
          id="inlineCheckbox6"
          value="adult"
          v-model="checkedFilter"
        />
        <label class="form-check-label" for="inlineCheckbox6">19</label>
      </div>
    </div>
  </div>

  <div class="row">
    <div
      class="col-6 col-md-4 col-lg-2 px-0"
      v-for="webtoon in webtoons"
      :key="webtoon.link"
    >
      <WebtoonCard :item="webtoon" />
    </div>
  </div>
  <div id="loadingObserver" ref="loading" class="text-center">
    <img src="/img-loading.gif" alt="loading image" v-show="!isLoadComplete" />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import WebtoonCard from "../components/WebtoonCard.vue";

export default {
  data() {
    return {
      skip: 0,
      limit: 6,
      isLoading: false,
      isLoadComplete: false,
      observer: new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log(`observe - isIntersecting`);
            this.loadMore();
            // const el = entry.target;
            // const index = parseInt(el.dataset.index);
            // 감지대상이 교차영역에 진입 할 경우
            // observer.unobserve(el);
          }
        });
      }),
    };
  },
  mounted() {
    console.log(`webtoons mounted.`);
    this.observer.disconnect();
    this.observer.observe(this.$refs.loading);
  },
  created() {
    console.log(`webtoons created.`);
    this.skip = 0;
    this.isLoading = false;
    this.isLoadComplete = false;
    this.loadGenres();
    this.loadReadWebtoons({ skip: 0, limit: 6 });
    // this.loadMore();
  },
  computed: {
    ...mapState("webtoon", ["webtoons", "genres", "readWebtoons"]),
    ...mapGetters("webtoon", ["webtoonListKey"]),
    checkedFilter: {
      get() {
        const { updated, isNew, seasonEnd, end, delay, adult } =
          this.$route.query;
        const arr = [];
        if (updated) arr.push("updated");
        if (isNew) arr.push("isNew");
        if (seasonEnd) arr.push("seasonEnd");
        if (end) arr.push("end");
        if (delay) arr.push("delay");
        if (adult) arr.push("adult");
        return arr;
      },
      set(arr) {
        const query = { ...this.$store.state.route.query };
        query.updated = arr.indexOf("updated") === -1 ? undefined : true;
        query.isNew = arr.indexOf("isNew") === -1 ? undefined : true;
        query.seasonEnd = arr.indexOf("seasonEnd") === -1 ? undefined : true;
        query.end = arr.indexOf("end") === -1 ? undefined : true;
        query.delay = arr.indexOf("delay") === -1 ? undefined : true;
        query.adult = arr.indexOf("adult") === -1 ? undefined : true;

        this.$router.push({
          name: this.$store.state.route.name,
          params: { ...this.$store.state.route.params },
          query,
        });
      },
    },
  },
  watch: {
    webtoonListKey() {
      console.log(`webtoonListKey changed - ${this.webtoonListKey}`);
      if (this.webtoonListKey.startsWith("WebtoonList")) {
        this.skip = 0;
        this.isLoading = false;
        this.isLoadComplete = false;
        this.loadMore();
        // this.$store.dispatch("webtoon/loadReadWebtoons", { skip: 0, limit: 6 });
      } else {
        this.isLoadComplete = true;
      }
    },
  },
  components: {
    WebtoonCard,
  },
  methods: {
    ...mapActions("webtoon", [
      "loadGenres",
      "loadWebtoons",
      "loadReadWebtoons",
    ]),
    isObserverShowing() {
      // console.log(`isObserverShowing: ${this.$refs.loading.getBoundingClientRect().top - 10 < window.innerHeight}, Bounding Rect top: ${this.$refs.loading.getBoundingClientRect().top}   inner Height: ${window.innerHeight}`)
      return (
        this.$refs.loading.getBoundingClientRect().top - 250 <
        window.innerHeight
      );
    },
    setGenrefilter(genre) {
      this.$router.push({
        name: this.$store.state.route.name,
        params: { ...this.$store.state.route.params },
        query: { ...this.$store.state.route.query, genre },
      });
    },
    async loadMore() {
      if (this.isLoading || this.isLoadComplete) return;
      this.isLoading = true;
      try {
        // console.log("load start");
        const loadingStartTime = new Date();
        const { isEnd, stop, limit } = await this.loadWebtoons({
          skip: this.skip,
          limit: this.limit,
        });

        this.isLoading = false;
        console.log(`load end - ${new Date() - loadingStartTime}ms`);
        if (isEnd) {
          this.isLoadComplete = true;
          console.log(`Load webtoon complete ${this.webtoons.length}`);
        } else {
          this.skip += limit;
          if (!this.isLoadComplete && !stop && this.isObserverShowing()) {
            console.log("observe - isObserverShowing");
            this.loadMore();
          }
        }
      } catch (err) {
        this.isLoading = false;
      }
    },
    removeSearch() {
      this.$router.push({
        name: this.$store.state.route.name,
        params: { ...this.$store.state.route.params },
        query: { ...this.$store.state.route.query, search: undefined },
      });
    },
  },
};
</script>

<style scoped>
.webtoon-card-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.genre-filter-item {
  cursor: pointer;
  font-size: 0.9rem;
}
.search-keyword-badge {
  cursor: pointer;
}
</style>
