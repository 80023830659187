export const login = async (username, password) => {
  const query = new URLSearchParams();
  query.append("username", username);
  query.append("password", password);
  const res = await fetch(`/api/login?${query}`);
  if (!res.ok) {
    const err = await res.json();
    throw new MemberError(err.code, res.status, err.message);
  }
  const { refreshToken } = await res.json();
  localStorage.setItem("refreshToken", refreshToken);
  return refreshToken;
};

export const logout = async () => {
  await fetch(`/api/logout`);
  localStorage.removeItem("refreshToken");
};

export const join = async (username, password) => {
  const query = new URLSearchParams();
  query.append("username", username);
  query.append("password", password);
  const res = await fetch(`/api/join?${query}`);
  if (!res.ok) {
    const err = await res.json();
    throw new MemberError(err.code, res.status, err.message);
  }
  return await res.json();
};

export const doRefreshToken = async () => {
  const query = new URLSearchParams();
  query.append("refreshToken", localStorage.getItem("refreshToken"));
  const res = await fetch(`/api/refresh-token?${query}`);
  if (!res.ok) {
    const err = await res.json();
    throw new MemberError(err.code, res.status, err.message);
  }
  const { refreshToken } = await res.json();
  localStorage.setItem("refreshToken", refreshToken);
  return refreshToken;
};

export const verify = async () => {
  const res = await fetchWithAuth("/api/verify");
  return res;
};

export const getWebtoons = async ({
  skip,
  limit,
  genre,
  isAdult,
  isDelay,
  isUpdated,
  isSeasonEnd,
  isNew,
  isEnd,
  search,
}) => {
  const query = new URLSearchParams();
  if (skip) query.append("skip", skip);
  if (limit) query.append("limit", limit);
  if (genre) query.append("genre", genre);
  if (isAdult) query.append("isAdult", isAdult);
  if (isDelay) query.append("isDelay", isDelay);
  if (isUpdated) query.append("isUpdated", isUpdated);
  if (isSeasonEnd) query.append("isSeasonEnd", isSeasonEnd);
  if (isNew) query.append("isNew", isNew);
  if (isEnd) query.append("isEnd", isEnd);
  if (search) query.append("search", search);
  return await fetchWithAuth(`/api/webtoons?${query}`);
};

export const getWebtoon = async (currentWebtoonLink) => {
  return await fetchWithAuth(`/api/webtoon/${currentWebtoonLink}`);
};

export const getEpisode = async (currentEpisodeLink) => {
  return await fetchWithAuth(`/api/episode/${currentEpisodeLink}`);
};

export const getGenres = async () => {
  return await fetchWithAuth(`/api/genres`);
};

export const getReadWebtoons = async (skip, limit) => {
  const query = new URLSearchParams();
  if (skip) query.append("skip", skip);
  if (limit) query.append("limit", limit);
  return await fetchWithAuth(`/api/read-webtoons?${query}`);
};

export const removeReadHistory = async (link) => {
  const query = new URLSearchParams();
  if (link) query.append("link", link);
  return await fetchWithAuth(`/api/remove-read-history?${query}`);
};

export const fetchWithAuth = async (...args) => {
  const res = await fetch(...args);
  if (res.status === 419) {
    // Token Expired.
    await doRefreshToken();
    return await fetchWithAuth(...args);
  }

  if (!res.ok) {
    if (res.status === 401) {
      const err = await res.json();
      throw new MemberError(err.code, res.status, err.message);
    } else {
      throw new Error(res.status);
    }
  }

  return await res.json();
};

export class MemberError extends Error {
  constructor(code = "GENERIC", status = 500, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, MemberError);
    }

    this.code = code;
    this.status = status;
  }
}
