import { createRouter, createWebHistory } from "vue-router";
import WebtoonList from "../views/WebtoonList.vue";
import Webtoon from "../views/Webtoon.vue";
import Episode from "../views/Episode.vue";
import Login from "../views/Login.vue";
import CreateAccount from "../views/CreateAccount.vue";
import Recently from "../views/Recently.vue";
import { verify, MemberError } from "../api-client.js";
import store from "../store";

const verifyToken = async (to) => {
  try {
    const { decoded, config } = await verify();
    store.commit("setMember", decoded);
    store.commit("setConfig", config);
  } catch (err) {
    if (err instanceof MemberError && err.status === 401) {
      const query = {
        name: to.name,
        params: JSON.stringify(to.params),
        query: JSON.stringify(to.query),
      };
      store.commit("setMember", null);
      store.commit("setConfig", {});
      return { name: "Login", query };
    } else {
      console.error(err.message);
      // [TODO] error 페이지로 보내야 할듯.
      const query = {
        name: to.name,
        params: JSON.stringify(to.params),
        query: JSON.stringify(to.query),
      };

      store.commit("setMember", null);
      store.commit("setConfig", {});
      return { name: "Login", query };
    }
  }
};

const routes = [
  {
    path: "/",
    name: "WebtoonList",
    component: WebtoonList,
    beforeEnter: verifyToken,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/create-account",
    name: "CreateAccount",
    component: CreateAccount,
  },
  {
    path: "/webtoon/:link",
    name: "Webtoon",
    component: Webtoon,
    beforeEnter: verifyToken,
  },
  {
    path: "/episode/:link",
    name: "Episode",
    component: Episode,
    beforeEnter: verifyToken,
  },
  {
    path: "/recently",
    name: "Recently",
    component: Recently,
    beforeEnter: verifyToken,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
