<template>
  <div class="row mb-4">
    <div class="col-auto">
      <img class="cover-image" :src="coverUrl" />
    </div>
    <div class="col">
      <h2>{{ webtoon?.title }}</h2>
      <div class="badge-layer mb-3">
        <small
          class="badge rounded-pill me-1 bg-danger"
          v-show="webtoon && webtoon.isAdult"
          >19</small
        >
        <small
          class="badge rounded-pill me-1 bg-warning text-dark"
          v-show="webtoon && webtoon.isUpdated"
          >Update</small
        >
        <small
          class="badge rounded-pill me-1 bg-success"
          v-show="webtoon && webtoon.isEnd"
          >완결</small
        >
        <small
          class="badge rounded-pill me-1 bg-primary"
          v-show="webtoon && webtoon.isSeasonEnd"
          >시즌 완결</small
        >
        <small
          class="badge rounded-pill me-1 bg-info text-dark"
          v-show="webtoon && webtoon.isDelay"
          >휴재</small
        >
        <small
          class="badge rounded-pill bg-secondary me-1"
          v-for="genre of webtoon ? webtoon.genre : []"
          :key="genre"
          >{{ genre }}</small
        >
      </div>
      <p>{{ webtoon?.summary }}</p>
    </div>
  </div>

  <div
    class="episode-item row border border-1 rounded-1 mb-3 mx-1"
    v-for="episode in episodes"
    :key="episode.link"
    @click="goEpisode(episode)"
    :ref="setEpisodeRef(episode.readDate)"
  >
    <div class="episode-number align-self-center col-auto">
      {{ episode.episode }}
    </div>
    <div class="episode-title align-self-center col">{{ episode.title }}</div>
    <div class="episode-date align-self-center col-sm-auto">
      <span class="badge bg-warning text-dark me-2" v-show="episode.readDate"
        ><i class="bi bi-book"></i>
        {{ timeForToday(episode.readDate) }} 읽음</span
      >
      {{ episode.date }}
    </div>
  </div>
  <div class="fixed-bottom">
    <div class="row">
      <div class="col"></div>
      <div class="navi-icons-box col-auto pe-4">
        <div>
          <i class="navi-icon bi bi-arrow-up-circle" @click="scrollToTop"></i>
        </div>
        <div v-show="lastReadEl">
          <i class="navi-icon bi bi-book" @click="scrollToLastRead"></i>
        </div>
        <div>
          <i
            class="navi-icon bi bi-arrow-down-circle"
            @click="scrollToBottom"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { timeForToday } from "../util.js";
export default {
  data() {
    return {
      lastReadEl: null,
    };
  },
  async created() {
    await this.loadWebtoon();
  },
  computed: {
    ...mapState("webtoon", ["webtoon"]),
    ...mapState(["config"]),
    ...mapGetters("webtoon", ["currentWebtoonLink"]),
    episodes() {
      if (!this.webtoon || !this.webtoon.episodes) return [];
      return this.webtoon.episodes;
    },
    coverUrl() {
      if (!this.webtoon || !this.webtoon.cover) return "";
      const query = new URLSearchParams();
      query.append("customOriginHost", this.config.toonSarangThumbnailUrl);
      query.append("customHostAlias", "webtoon-thumbnail");
      return `/image-cache${this.webtoon.cover}?${query}`;
    },
  },
  watch: {
    currentWebtoonLink() {
      this.loadWebtoon();
    },
  },
  methods: {
    async loadWebtoon() {
      await this.$store.dispatch("webtoon/loadWebtoon");
    },
    goEpisode(episode) {
      this.$router.push({
        name: "Episode",
        params: { link: episode.link.slice(1) },
      });
    },
    timeForToday(value) {
      return timeForToday(value);
    },
    setEpisodeRef(readDate) {
      return (el) => {
        if (readDate && !this.lastReadEl) {
          this.lastReadEl = el;
        }
      };
    },
    scrollToTop() {
      const scrollingElement = document.scrollingElement || document.body;
      scrollingElement.scrollTop = 0;
    },
    scrollToLastRead() {
      if (this.lastReadEl) {
        window.scrollTo(0, this.lastReadEl.offsetTop - 50);
      }
    },
    scrollToBottom() {
      const scrollingElement = document.scrollingElement || document.body;
      scrollingElement.scrollTop = scrollingElement.scrollHeight;
    },
  },
};
</script>

<style scoped>
.episode-item {
  cursor: pointer;
}
.episode-item:hover {
  background: rgb(124, 17, 196);
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}
.episode-number {
  font-size: 2rem;
  font-weight: bold;
}
.episode-title {
  font-size: 1.5rem;
}
.cover-image {
  width: 100%;
}
.navi-icon {
  font-size: 2rem;
  cursor: pointer;
}
</style>
