<template>
  <div class="row">
    <div class="col episode-title">
      <h1>{{ episode?.title }}</h1>
    </div>
  </div>
  <div class="row px-0">
    <div
      class="image-box"
      v-for="(image, index) in images"
      :key="image"
      style="height: 1000px"
    >
      <img
        class="image"
        :data-url="image"
        :data-index="index"
        src="/img-loading.gif"
        :ref="setImageRef"
      />
    </div>
  </div>
  <div class="fixed-bottom">
    <div class="row">
      <div class="col"></div>
      <div class="navi-icons-box col-auto">
        <i
          class="navi-icon bi bi-caret-left-fill align-middle"
          :class="{ disabled: !prevLink }"
          @click="goPrev"
        ></i>
        <i
          class="navi-icon bi bi-card-list mx-3 align-middle"
          @click="goList"
        ></i>
        <i
          class="navi-icon bi bi-caret-right-fill align-middle"
          :class="{ disabled: !nextLink }"
          @click="goNext"
        ></i>
      </div>
      <div class="col"></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      observer: new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          // console.log(`observe - ${entry.target.dataset.index}`)
          if (entry.isIntersecting) {
            const el = entry.target;
            const index = parseInt(el.dataset.index);
            // 감지대상이 교차영역에 진입 할 경우
            observer.unobserve(el);
            this.loadImage(index); // 교차영역 들어올경우 이미지 로딩
            this.loadImage(index + 1); // 교차영역 들어올경우 이미지 로딩
            this.loadImage(index + 2); // 교차영역 들어올경우 이미지 로딩
          }
        });
      }),
      imgRefs: new Map(),
    };
  },
  async created() {
    // console.log(`created - ${decodeURIComponent(this.$route.path)}`)
    await this.loadEpisode();
  },
  computed: {
    ...mapState("webtoon", ["episode"]),
    ...mapGetters("webtoon", ["currentEpisodeLink"]),
    images() {
      if (!this.episode || !this.episode.images) return [];
      return this.episode.images.map((img) => `/image-cache/${img}`);
    },
    prevLink() {
      if (!this.episode || !this.episode.prev || !this.episode.prev.link)
        return null;
      return this.episode.prev.link;
    },
    nextLink() {
      if (!this.episode || !this.episode.next || !this.episode.next.link)
        return null;
      return this.episode.next.link;
    },
  },
  watch: {
    currentEpisodeLink(to) {
      if (to) {
        // change
        console.log(`episode - change ${to}`);
        this.loadEpisode();
      } else {
        // out
        console.log(`episode - out`);
        this.observer.disconnect();
      }
    },
  },
  methods: {
    loadImage(index) {
      if (!this.imgRefs.has(index)) return;
      const { el } = this.imgRefs.get(index);
      this.imgRefs.delete(index);

      console.log(`lazyload index ${index}`);
      el.src = el.dataset.url;
      el.parentElement.style.height = null;
      el.style.width = "100%";
      el.style.maxWidth = "800px";
    },
    setImageRef(el) {
      if (!el) return;
      const index = parseInt(el.dataset.index);
      this.imgRefs.set(index, { el });

      if (!window["IntersectionObserver"]) {
        this.loadImage(index);
        return;
      }

      this.observer.observe(el);
    },
    async loadEpisode() {
      this.imgRefs = new Map();
      this.observer.disconnect();
      await this.$store.dispatch("webtoon/loadEpisode");
    },
    goPrev() {
      if (!this.prevLink) return;
      this.$router.push({
        name: "Episode",
        params: { link: this.prevLink.slice(1) },
      });
    },
    goList() {
      if (!this.episode || !this.episode.webtoon) return;
      this.$router.push({
        name: "Webtoon",
        params: { link: this.episode.webtoon.slice(1) },
      });
    },
    goNext() {
      if (!this.nextLink) return;
      this.$router.push({
        name: "Episode",
        params: { link: this.nextLink.slice(1) },
      });
    },
  },
};
</script>

<style scoped>
.episode-title {
  text-align: center;
}
.image-box {
  padding: 0px;
  text-align: center;
}
.navi-icons-box {
  font-size: 2.5rem;
  background: rgba(255, 255, 255, 0.452);
  color: rgb(0, 0, 0);
  border-top-left-radius: 0.4em;
  border-top-right-radius: 0.4em;
}
.navi-icon {
  cursor: pointer;
}
.navi-icon:hover {
  color: rgb(0, 64, 121);
}
.navi-icon.disabled,
.navi-icon.disabled:hover {
  color: grey;
}
</style>
