<template>
  <div class="row mt-5">
    <div class="col-md"></div>
    <div class="col-md-4">
      <h2>로그인</h2>
      <form @submit.prevent="onSubmit">
        <div class="mb-3">
          <label for="usernameInput" class="form-label">Username</label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': isInvalidUsername }"
            id="usernameInput"
            aria-describedby="usernameHelp"
            v-model="username"
          />
          <div class="invalid-feedback">
            Can not find username '{{ lastInputUsername }}'
          </div>
        </div>
        <div class="mb-3">
          <label for="passwordInput" class="form-label">Password</label>
          <input
            type="password"
            class="form-control"
            :class="{ 'is-invalid': isInvalidPassword }"
            id="passwordInput"
            aria-describedby="passwordHelp"
            v-model="password"
          />
          <div class="invalid-feedback">Wrong password</div>
        </div>
        <p class="text-danger" v-show="errorMessage">{{ errorMessage }}</p>
        <div class="d-grid gap-3">
          <button type="submit" class="btn btn-primary">로그인</button>
          <button
            type="button"
            class="btn btn-success"
            @click="goCreateAccount"
          >
            계정 만들기
          </button>
        </div>
      </form>
    </div>
    <div class="col-md"></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    username: "",
    password: "",
    lastInputUsername: "",
    isInvalidUsername: false,
    isInvalidPassword: false,
    errorMessage: "",
  }),
  methods: {
    ...mapActions(["login"]),
    async onSubmit() {
      this.isInvalidUsername = false;
      this.isInvalidPassword = false;
      this.errorMessage = "";
      try {
        await this.login({
          username: this.username,
          password: this.password,
        });

        const { name, params, query } = this.$store.state.route.query;
        if (!name) {
          this.$router.push({ path: "/" });
        } else {
          this.$router.push({
            name,
            params: JSON.parse(params),
            query: JSON.parse(query),
          });
        }
      } catch (err) {
        if (err.code === "MEMBER_NOT_FOUND") {
          this.lastInputUsername = this.username;
          this.isInvalidUsername = true;
        } else if (err.code === "WRONG_PASSWORD") {
          this.isInvalidPassword = true;
        } else if (err.code === "NOT_APPROVED_MEMBER") {
          this.errorMessage = "관리자 승인 대기중 입니다.";
        }
      }
    },
    goCreateAccount() {
      this.$router.push({ name: "CreateAccount" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
